$transition-duration: 0.8s;
$transition-easing: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$bounce-easing: cubic-bezier(0.175, 0.885, 0.320, 1.275);
$closed-width: 100px;
$closed-height: 50px;
$opened-width: 400px;
$opened-height: 160px;

@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);

.flip-btn {
    display: block;
    position: relative;
    width: $closed-width;
    height: $closed-height;
    transition: width $transition-duration $transition-easing,
        height $transition-duration $transition-easing,
        transform $transition-duration $bounce-easing;
    transform-style: preserve-3d;
    transform-origin: 50% 50%;
    text-align: center;
    transition: transform 0.5;
}

.flip-btn-front {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    line-height: $closed-height;
    background-color: #F44336;
    color: #fff;
    cursor: pointer;
    backface-visibility: hidden;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: background 0.15s ease,
        line-height $transition-duration $transition-easing;
}

.flip-btn-front:hover {
    background-color: lighten(#F44336, 10%);
}

.flip-btn.is-open .flip-btn-front {
    pointer-events: none;
    line-height: $opened-height;
}

.flip-btn-back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #eee;
    color: #222;
    transform: translateZ(-2px) rotateX(180deg);
    overflow: hidden;
    transition: box-shadow $transition-duration ease;
}

.flip-btn-back p {
    margin-top: 27px;
    margin-bottom: 25px;
}

.flip-btn-back button {
    padding: 12px 20px;
    width: 30%;
    margin: 0 5px;
    background-color: transparent;
    border: 0;
    border-radius: 2px;
    font-size: 1em;
    cursor: pointer;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: background 0.15s ease;

    &:focus {
        outline: 0;
    }

    &.yes {
        background-color: #2196F3;
        color: #fff;

        &:hover {
            background-color: lighten(#2196F3, 10%);
        }
    }

    &.no {
        color: #2196F3;

        &:hover {
            background-color: #ddd;
        }
    }
}

.flip-btn.is-open .flip-btn-back {
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.4);
}

.flip-btn[data-direction="left"] .flip-btn-back,
.flip-btn[data-direction="right"] .flip-btn-back {
    transform: translateZ(-2px) rotateY(180deg);
}

.flip-btn.is-open {
    width: $opened-width;
    height: $opened-height;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99999;
}

.flip-btn[data-direction="top"].is-open {
    transform: translate(-50%, -50%) rotateX(180deg);
}

.flip-btn[data-direction="right"].is-open {
    transform: translate(-50%, -50%) rotateY(180deg);
}

.flip-btn[data-direction="bottom"].is-open {
    transform: translate(-50%, -50%) rotateX(-180deg);
}

.flip-btn[data-direction="left"].is-open {
    transform: translate(-50%, -50%) rotateY(-180deg);
}