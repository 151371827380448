/* Full-screen overlay styles */
.fullpage-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(221, 216, 216, 0.106);
    /* Semi-transparent background */
    backdrop-filter: blur(5px);
    /* Apply a blur effect to the background */
    z-index: 1000;
    pointer-events: none;
    /* Make sure it's above other content */
    /* display: none; */
    /* Initially hidden */
    /* transition: background-color 0.3s ease, backdrop-filter 0.3s ease; */
    transition: backdrop-filter 0.5s ease-in-out;

}

/* Loading component styles */
.fullpage-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    pointer-events: none;
    /* Higher z-index than the overlay */
    /* display: none; */
    /* Initially hidden */
    transition: opacity 0.3s ease;
}