.clock-App {
    display: flex;
    justify-content: space-around;
    font-family: sans-serif;
    text-align: center;
    padding: 40px;
}

.time {
    font-size: 32px;
}

h1 {
    font-family: "Roboto";
    text-align: center;
    margin-bottom: 40px;
}

.timer-wrapper {
    display: flex;
    justify-content: center;
}

.time-wrapper {
    position: relative;
    width: 80px;
    height: 60px;
    font-size: 48px;
    font-family: "Montserrat";
}

.time-wrapper .time {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(0);
    opacity: 1;
    transition: all 0.2s;
}

.time-wrapper .time.up {
    opacity: 0;
    transform: translateY(-100%);
}

.time-wrapper .time.down {
    opacity: 0;
    transform: translateY(100%);
}

.timer-dimension {
    font-size: large;
    font-weight: bold;
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: -10px;
}


@media screen and (max-width: 480px) {
    .clock-App {
        scale: 50%;
    }
}