#clock2 {

    background-color: rgba(0, 0, 0, .15);
}

.clock {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 80%;

    div {
        background-color: rgba(255, 255, 255, .25);
        color: #000;
        display: inline-block;
        padding: 2rem;
        margin: 0 1rem;
        font-size: 2.5rem;
        // width: calc(10% - 2rem);
        text-align: center;
        font-weight: bold;
        border-radius: 5%;

        &[data-value*="days"],
        &[data-value*="hours"],
        &[data-value*="minutes"],
        &[data-value*="seconds"] {
            background-color: rgba(0, 0, 0, .15);
        }

        &[data-value*="days"]:after,
        &[data-value*="hours"]:after,
        &[data-value*="minutes"]:after,
        &[data-value*="seconds"]:after {
            display: block;
            font-size: .75rem;
            margin-top: .25rem;
            font-weight: 300;
        }

        &[data-value*="days"]:after {
            content: "Days"
        }

        &[data-value*="hours"]:after {
            content: "Hours"
        }

        &[data-value*="minutes"]:after {
            content: "Minutes"
        }

        &[data-value*="seconds"]:after {
            content: "Seconds"
        }
    }
}

@media screen and (max-width: 820px) {
    .clock {
        max-width: 90%;

        div {
            // width: calc(15% - 2rem)
        }
    }
}

@media screen and (max-width: 767px) {
    .clock {
        max-width: 100%;

        div {
            // width: calc(30% - 4rem);
            margin: .5rem;
            padding: .5rem;
            font-size: 1rem;

            &[data-value*="days"]:after,
            &[data-value*="hours"]:after,
            &[data-value*="minutes"]:after,
            &[data-value*="seconds"]:after {
                font-size: .5rem;
            }
        }
    }
}