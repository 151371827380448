:root {
    --base_scale: 5vh;
    --floor: 15vh;
    --color: #836ee5;
}

// *,
// *:before,
// *:after {
//     box-sizing: border-box
// }

// html {
//     min-height: 100vh
// }

// body {
//     background: #eee;
//     min-height: 100vh;
//     padding-bottom: var(--floor);
//     border-bottom: var(--floor) solid #fff;
//     overflow-y: hidden;
// }

.shapes-loader span {
    position: absolute;
    display: block;
    left: 50%;
    bottom: 0;
    margin-left: calc(-1 * (var(--base_scale) / 2));
    margin-bottom: var(--floor);
    mix-blend-mode: var(--mix-blend);

    &.circle {
        width: var(--base_scale);
        height: var(--base_scale);
        background: var(--color);
        border-radius: 50%;
    }

    &.semi-circle {
        height: calc(var(--base_scale) * 2);
        width: var(--base_scale);
        background: var(--color);
        border-bottom-right-radius: calc(var(--base_scale) * 2);
        border-top-right-radius: calc(var(--base_scale) * 2);
    }

    &.square {
        width: var(--base_scale);
        height: var(--base_scale);
        background: var(--color);
    }

    &.rectangle {
        width: calc(var(--base_scale) * 1.5);
        height: var(--base_scale);
        background: var(--color);
    }

    &.triangle {
        width: 0;
        height: 0;
        border-bottom: var(--base_scale) solid var(--color);
        border-right: var(--base_scale) solid transparent;
    }

    &.triangle-2 {
        width: 0;
        height: 0;
        border-top: var(--base_scale) solid var(--color);
        border-left: var(--base_scale) solid transparent;
    }

    &.oval {
        width: calc(var(--base_scale) * 2);
        height: var(--base_scale);
        border-radius: var(--base_scale) / calc(var(--base_scale) / 2);
        background: var(--color);
    }

    &.oval-2 {
        width: var(--base_scale);
        height: calc(var(--base_scale) * 2);
        border-radius: calc(var(--base_scale) / 2) / var(--base_scale);
        background: var(--color);
    }

    &.egg {
        display: block;
        width: calc(var(--base_scale) / 10 * 7);
        height: var(--base_scale);
        background-color: var(--color);
        border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
    }

    &.bounce-up {
        --bounce-variance: 0vh;
        --bounce-height: calc(-1 * 50vh - calc(var(--base_scale) - var(--floor) + var(--bounce-variance)));
        transition: transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateY(var(--bounce-height)) rotate(0deg);
    }

    &.bounce-down {
        transition: transform 300ms cubic-bezier(0.6, 0.04, 0.98, 0.335);
        transform: translateY(0) rotate(var(--rotation));
    }
}