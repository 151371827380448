$clockHalfHeight: 0.72em;
$clockBorderRadius: 0.15em;

/* @halfHeight: 0.72em; */
/* @borderRadius: 0.15em; */

.flip-clock {
    text-align: center;
    perspective: 400px;
    margin: 20px auto;

    // *,
    // *:before,
    // *:after {
    //     box-sizing: border-box;
    // }
}

.flip-clock *,
.flip-clock *::before,
.flip-clock *::after {
    box-sizing: border-box;
}

.flip-clock__piece {
    display: inline-block;
    margin: 0 5px;
}

.flip-clock__slot {
    font-size: 2vw;
}



.card {
    display: block;
    position: relative;
    padding-bottom: $clockHalfHeight;
    font-size: 9vw;
    line-height: 0.95;
}

.card__top,
.card__bottom,
.card__back::before,
.card__back::after {
    display: block;
    height: $clockHalfHeight;
    color: #ccc;
    background: #2a2a2a;
    padding: 0.25em 0.25em;
    border-radius: $clockBorderRadius $clockBorderRadius 0 0;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    width: 1.8em;
    transform: translateZ(0);
}

.card__bottom {
    color: #FFF;
    position: absolute;
    top: 50%;
    left: 0;
    border-top: solid 1px #000;
    background: #393939;
    border-radius: 0 0 $clockBorderRadius $clockBorderRadius;
    pointer-events: none;
    overflow: hidden;
}

.card__bottom::after {
    display: block;
    margin-top: -$clockHalfHeight;
}

.card__back::before,
.card__bottom::after {
    content: attr(data-value);
}

.card__back {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0%;
    pointer-events: none;
}

.card__back::before {
    position: relative;
    z-index: -1;
    overflow: hidden;
}

// .flip .card__back::before {
//     animation: flipTop 0.3s cubic-bezier(.37, .01, .94, .35);
//     animation-fill-mode: both;
//     transform-origin: center bottom;
// }

._flip .card__back::before {
    -webkit-animation: flipTop 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35);
    animation: _flipTop 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    transform-origin: center bottom;
}

.flip .card__back::before {
    -webkit-animation: flipTop 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35);
    animation: flipTop 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    transform-origin: center bottom;
}

._flip .card__back .card__bottom {
    transform-origin: center top;
    animation-fill-mode: both;
    animation: _flipBottom 0.6s cubic-bezier(.15, .45, .28, 1);
    /* 0.3s;  */
}

.flip .card__back .card__bottom {
    transform-origin: center top;
    animation-fill-mode: both;
    animation: flipBottom 0.6s cubic-bezier(.15, .45, .28, 1);
    /* 0.3s;  */
}

@keyframes flipTop {
    0% {
        transform: rotateX(0deg);
        z-index: 2;
    }

    0%,
    99% {
        opacity: 0.99;
    }

    100% {
        transform: rotateX(-90deg);
        opacity: 0;
    }
}

@keyframes flipBottom {

    0%,
    50% {
        z-index: -1;
        transform: rotateX(90deg);
        opacity: 0;
    }

    51% {
        opacity: 0.99;
    }

    100% {
        opacity: 0.99;
        transform: rotateX(0deg);
        z-index: 5;
    }
}

@keyframes _flipTop {
    0% {
        transform: rotateX(0deg);
        z-index: 2;
    }

    0%,
    99% {
        opacity: 0.99;
    }

    100% {
        transform: rotateX(-90deg);
        opacity: 0;
    }
}

@keyframes _flipBottom {

    0%,
    50% {
        z-index: -1;
        transform: rotateX(90deg);
        opacity: 0;
    }

    51% {
        opacity: 0.99;
    }

    100% {
        opacity: 0.99;
        transform: rotateX(0deg);
        z-index: 5;
    }
}